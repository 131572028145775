import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Topic } from '@shapeable/gesda-types';
import { CellLabel, DarkMode, EntityBanner, EntityBannerProps, EntityLinkValue, EntityMetaSection, EntityStack, PersonValue, useLang, useLink } from '@shapeable/ui';
import { useCurrentEditionChairs } from '../../hooks/use-current-edition-chairs';
const cls = classNames('topic-banner-layout');

// -------- Types -------->

// -------- Props -------->

export type TopicBannerLayoutProps = Classable & HasChildren & EntityBannerProps & { 
  entity?: Topic;
};

export const TopicBannerLayoutDefaultProps: Omit<TopicBannerLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const BannerStyles = breakpoints({
  base: css`
    
  `,
});

const ModeratorsStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(6)};
    color: ${theme.COLOR('bright')};
  `,
});

const ModeratorTitleStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('sub')};
    font-weight: 300;
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const ModeratorValuesStyles = breakpoints({
  base: css`
    font-size: ${10 / 16}em;
    color: ${theme.COLOR('bright')};
    padding: 0;
    .shp--entity-value__body {
      align-items: flex-start;
      padding-right: ${theme.UNIT(2)};
    }
  `,
  tablet: css`
    display: flex;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Banner: styled(EntityBanner)`${BannerStyles}`,

    Moderators: styled.div`${ModeratorsStyles}`,
      ModeratorTitle: styled(CellLabel)`${ModeratorTitleStyles}`,
        ModeratorValues: styled(EntityMetaSection)`${ModeratorValuesStyles}`, 
};

export const TopicBannerLayout: Shapeable.FC<TopicBannerLayoutProps> = (props) => {
  const { className, children, entity, ...rest } = props;

  const { committeeChairs = [] } = entity;

  const currentEditionChairs = useCurrentEditionChairs(committeeChairs)
  const t = useLang();
  const { Link } = useLink();
  const hasChairs = !!currentEditionChairs.length;

  return (
    <DarkMode>
    <My.Container>
        <My.Banner 
          {...rest}
          maxHeight={500}
          variant='overlay'
          entity={entity}
          entityValueProps={{ showImage: false, showOutlineNumber: true, outlineBubble: { numberSize: 0.6, showBorder: true, showImage: false }}}
          >
        {
          hasChairs && (
            <My.Moderators>
              <My.ModeratorTitle>{t('Anticipation Committee Chairs')}</My.ModeratorTitle>
              <My.ModeratorValues 
                items={currentEditionChairs} 
                showAllItems={false} 
                direction='row' 
                color='bright'
              />
            </My.Moderators>
          )
        }
         </My.Banner>
      </My.Container>
      </DarkMode>
  )
};

TopicBannerLayout.defaultProps = TopicBannerLayoutDefaultProps;
TopicBannerLayout.cls = cls;